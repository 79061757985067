const statusLabel = {
    complete: 'Complete',
    ship: 'Shipped',
    inprogress: 'In Progress',
    pending: 'Pending',
    action: 'Action',
    transfer: 'Transmit',
    success: 'Success',
    transmitting: 'Transmitting',
    failed: 'Failed',
    null: 'Transmit',
}

export default statusLabel
