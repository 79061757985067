const messageList = {
    processing: {
        inprogress: {
            status: 'This prescription is being prepared by PharmaSmart!',
            label: 'In Progress',
            color: 'primary',
            text: 'PharmaSmart is processing your prescription!',
            subText: 'Our team is filling your Order. <br><br> <span class="tw-text-red-800  tw-text-xl">Please wait for further updates.  <b>Thank you!</b></span>',
        },
        pending: {
            status: 'Please click blue button to get your prescription',
            label: 'Pending',
            color: 'warning',
            text: 'Please let us know how to proceed!',
            subText: '<strong class="tw-text-red-800  tw-text-xl">Thank you for completing your profile. Please click the blue button to get your prescription!</strong>'
        },
        transfer: {
            status: 'Transmit Prescription to a pharmacy of your choice!',
            label: 'Transmit',
            color: 'error',
            text: 'Transmit your Prescription',
            subText: 'Please have your pharmacy name and fax number ready to transmit your prescription. Once you fill the form to transmit your prescription, you will receive an email confirming success or failure to allow you to retry.'
        },
        complete: {
            status: 'Your Prescription was filled!',
            label: 'Transmit',
            color: 'error',
            text: 'Your Prescription was filled!',
            subText: '<span class="tw-text-red-800  tw-text-xl">Please look out for a Payment update!</span>'
        }
    },
    payment: {
        inprogress: {
            status: 'Check your email for your invoice!',
            label: 'In Progress',
            color: 'primary',
            text: 'Check your email for your invoice!',
            subText: 'Once invoice is paid, we will ship out your medication as soon as possible!'
        }
    },

}

export default messageList
